import { 
    Stack, 
    Heading, 
    Text, 
    Box, 
    useColorModeValue 
} from '@chakra-ui/react'

const HowItWorksItem = ({ step, heading, subheading }) => {
    const textColor = useColorModeValue('gray.700', 'gray.200')
    const borderColor = useColorModeValue('black', 'white')

    return (
        <Stack direction={'row'} spacing={5}>
            <Box>
                <Heading
                    fontWeight={600}
                    fontSize={'3xl'}
                    border={'1px'}
                    borderRadius={'full'}
                    w={'60px'}
                    h={'60px'}
                    textAlign={'center'}
                    lineHeight={'auto'}
                    color={textColor}
                    borderColor={borderColor}
                >{step}</Heading>
            </Box>
            <Stack direction={'column'}>
                <Heading fontWeight={600} fontSize={'3xl'} >{heading}</Heading>
                <Text fontSize={'lg'} color={textColor}>{subheading}</Text>
            </Stack>
        </Stack>
    )
}

export default HowItWorksItem