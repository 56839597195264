import {
    Flex,
    Container,
    Stack,
    Heading,
    Box,
    useColorModeValue
} from '@chakra-ui/react'

import CustomHighlight from '../Elements/CustomHighlight'

import HowItWorksItem from './HowItWorksItem'

const HowItWorks = ({ data }) => {
    const bgColor = useColorModeValue('gray.100', 'gray.800')
    return (
        <Flex
            w={'full'}
            py={10}
            bgColor={bgColor}
        >
            <Container
                maxW={'7xl'}
            >
                <Stack textAlign={'center'}
                >
                    <Heading fontWeight={700} fontSize={'4xl'}>
                        <CustomHighlight query={['How It Works']}>How It Works</CustomHighlight>
                    </Heading>
                </Stack>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    justify={'space-between'}
                    spacing={{ base: 10 }}
                    py={24}
                >
                    {
                        data?.map(step => {
                            return (
                                <Box flex={'1'} key={step.step}>
                                    <HowItWorksItem step={step?.step} heading={step?.heading} subheading={step?.subheading} />
                                </Box>
                            )
                        })
                    }
                </Stack>
            </Container>
        </Flex>
    )
}

export default HowItWorks
